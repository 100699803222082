.LogEntry-DayRate {
  text-align: center;
  margin: auto;
}

.LogEntry-DayRate p {
  text-align: left;
  /* margin-top: 10px; */
  margin: 0px;
  margin-left: 5px;
  font-weight: bold;
}

.LogEntry-IsCurrentDate {
  /* border: 1px solid red; */
  border-radius: 15px;
  background-color: rgba(255, 99, 132, 0.1  );
}

.LogEntry-Day {
  background-color: white;
}

.LogEntry {
  /* padding: 2px; */
  margin-bottom: 15px;
}
