.BulletSelector-List {
  list-style: none;
  margin: 0px;
  padding: 0px;
  clear: both;
  height: 45px;
}

.BulletSelector-List li {
  float: left;
}

.BulletSelector-List .Item {
  /* border-radius: 25px;
  width: 30px;
  height: 30px;
  border: 1px solid black;
  margin: 2px; */
  border-radius: 15px;
  border:1px solid black;
  width: 25px;
  height: 25px;
  margin: 4px;
  cursor: pointer;
}


.BulletSelector-List .Item-Active {
  background-color: green;
  color: white;
}

.BulletSelector-List .Item:hover:not(.Item-Active) {
  background-color: #eee;
}
