.Step1-Options {
  /* display: inline-block; */
  margin: 25px;
}
.Step1-Card {
  display: inline-block;
  margin: 10px;
}

.Step1-Icon {
  margin-right: 10px;
  font-size: 20px;
}
