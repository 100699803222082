@import './styles/_colors.scss';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // background-color: #eee !important;
  background: linear-gradient(to right, #e6f7ff, white);
  background-repeat:no-repeat;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.Pointer {
  cursor: pointer;
}

.bg-Awesome {
  background-color: $level1;
}

.bg-Good {
  background-color: $level2;
}

.bg-Alright {
  background-color: $level3;
}

.bg-Meh {
  background-color: $level4;
}

.bg-Horrible {
  background-color: $level5;
}

.bg-green {
  background-color: green !important;
  color: white !important;
}

.Bullet {
  margin: 10px;
  padding: 10px;
  border-radius: 20px;
  border: 1px solid black;
  display: inline-block;
  cursor: pointer;
  min-width: 43px;
  min-height: 43px;
  box-shadow: 0 2px 3px #ccc;
}

.list-unstyle {
  margin: 0px;
  list-style: none;
  padding: 0px;
}

.Container {
  margin: 20px;
  // margin-top: 0px;
}

@media (max-width:500px) {
  .Container {
    margin: 5px;
    // margin-top: 0px;
  }
}

.Form-Select {
  padding: 5px;
  border-radius: 5px;
}

.SwitchName {
  display: inline-block;
  margin-left: 10px;
}

.ant-btn {
  a, span {
    // text-transform: capitalize;
  }
}

.cap {
  text-transform: capitalize;
  a, span, div {
    text-transform: capitalize;
  }
}

.pull-right {
  float:right;
}

.pull-left {
  float:left;
}

.text-left {
  text-align: left;
}
