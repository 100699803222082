.MoodEntry {
  display: inline-block;
  margin-left: 10px;
  padding: 5px;
}

.MoodEntry-IsCurrentDate {
  background-color: rgba(255, 99, 132, 0.1  );
  border-radius: 15px;
}

.MoodEntry-Day {
  background: none;
}

/* .MoodEntry {
  border:1px solid black;
  padding: 10px;
  width: 40px;
  height: 40px;
  margin: 10px auto;
  cursor:pointer;
  display: inline-block;
  margin-left: 1px;
}

.MoodEntry-Moods {
  list-style: none;
  margin: 0px;
  padding: 0px;
}

.MoodEntry-MoodButton {
  width: 100%;
}

.MoodEntry-MoodButton-Awesome {
  background-color: #C1E7E3;
  color: black;
}

.MoodEntry-MoodButton-Good {
  background-color: #FFF49C;
  color: black;
}

.MoodEntry-MoodButton-Alright {
  background-color: #FF756D;
  color: black;
}

.MoodEntry-MoodButton-Meh {
  background-color: #CEC8E4;
  color: black;
}

.MoodEntry-MoodButton-Horrible {
  background-color: #82B3FF;
  color: black;
}

.MoodEntry-Awesome {
  background-color: #C1E7E3;
  color: black;
}

.MoodEntry-Good {
  background-color: #FFF49C;
  color: black;
}

.MoodEntry-Alright {
  background-color: #FF756D;
  color: black;
}

.MoodEntry-Meh {
  background-color: #CEC8E4;
  color: black;
}

.MoodEntry-Horrible {
  background-color: #82B3FF;
  color: black;
} */
