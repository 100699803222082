.Question h3 {
 
}

.Question-Buttons button {
  margin-left: 10px;
}

.Question-BackButton {
  text-align: left;
}
