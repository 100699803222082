.MonthToolbar {
  margin-top: 10px;
}
.MonthToolbar div {
  display: inline-block;
}

.MonthToolbar-Bakckward {
  display: inline-block;
  margin-right: 25px;
}

.MonthToolbar-Forward {
  margin-left: 25px;
}
