.AnswerRecomendations-Group {
  text-align: left;
  margin-bottom: 15px;
  padding-left: 20px;
  .AnswerRecomendations-Group-Label {
    // font-weight: bold;
    display: block;
    text-transform: capitalize;
  }
  ul {
    list-style: none;
    margin: 0px;
    padding: 0px;
  }
}

.AnswersRecomendations-Buttons {
  text-align: center;
  margin-top: 25px;
  margin-bottom: 25px;
  button {
    margin-left: 10px;
  }
}

.AnswersRecomendations-Form {
  text-align: left;
  // margin-left: 15px;
  display: inline-block;
  margin: auto;
}

.AnswerRecomendations-Tracker-Red {
  .SwitchName {
    color: red;
  }
}

.AnswerRecomendations-Tracker-Green {
  .SwitchName {
    color: green;
  }
}

.AnswerRecomendations-Switch {
  text-transform: capitalize;
}

.AnswersRecomendations-ManyTrackers {
  font-weight: bold;
  margin-top: 10px;
}

.AnswersRecomendations-Spin {
  padding: 25px;
}
