.ScrollableToolbar-Title {
  /* text-decoration: underline; */
  cursor: pointer;
  padding: 5px;
}


@media (max-width:700px) {
  .ScrollableToolbar-Title h1 {
    font-size:22px;
  }
}

@media (max-width:500px) {
  .ScrollableToolbar-Title h1 {
    font-size:12px;
  }
}

.ScrollableDate-Week {
  /* display: block; */
  /* text-decoration: underline; */
  margin-right: 10px;
  color: red;
}

.ScrollableDate-Dates {
  margin-right: 10px;
  /* text-decoration: underline; */
  /* display: block; */
}

.ScrollableDate-Today {
  text-decoration: underline;
  color: blue;
}

.ScrollableToolbar-Title:hover, .ScrollableDate-Week:hover, .ScrollableDate-Dates:hover {
  /* color: purple; */
}

.ScrollableToolbar-Title:hover {
  background-color: #ccc;
  border-radius: 5px;
}

.ScrollableDate-BackButton {
  /* display: block; */
  margin: 10px;
}

@media (max-width:500px) {
  .ScrollableDate-Today {
    display: block;
  }
  .ScrollableDate-Dates {
    display: block;
  }
  .ScrollableDate-Week {
    display: block;
  }
}
