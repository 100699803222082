// $level1:#C1E7E3;
// $level2:#FFF49C;
// $level3:#FF756D;
// $level4:#CEC8E4;
// $level5:#82B3FF;

$primary-color: #1890ff;

$level1:#01FF70;
$level2:#2ECC40;
$level3:#FFDC00;
$level4:#FF851B;
$level5:#FF4136;
