.Watcher-Body label {
  display: block;
  font-size: 16px;
}

.Watcher-Body label span{
  font-weight: bold;
}

.Watcher-Body {
  text-align: left;
  margin: auto;
}

.Watcher-Body ul {
  list-style: none;
  padding-left: 10px;
  /* margin: 0px; */
}

.Watcher-Body ul li {
  text-transform: capitalize;
}

.Watcher-Body-Trackers {
  font-weight: bold;
}

.Watcher-Body-Trackers span {
  font-weight: normal;
  font-size: 12px;
  color: gray;
  margin-left: 10px;
}

.Watcher-Score {
  padding: 5px;
  border-radius: 15px;
}

.Watcher-Orange {
  background-color: orange;
  padding: 5px;
  border-radius: 15px;
}

.Watcher-Table {
  overflow: scroll;
}

.Watcher-Table-Header {
  text-transform: capitalize;
}

.Watcher-Chart {
  margin-top: 25px;
  text-align: center;
}

.Watcher-Table-Tr {
  border-bottom: 1px solid black;
}

.Watcher-Body-Header {
  text-align: right;
}

.WatchersList-Empty {
  margin-top: 25px;
}

.WatchersList-Empty p {
  margin-top: 10px;
  text-align: left;
}
.WatchersList-Empty ul {
  /* list-style: none; */
  text-align: left;
  padding-left: 20px;
}

.WatchersList {
  margin-top: 10px;
}
