@import '../../styles/_colors.scss';
.WatcherSummaryCard {
  /* display: inline-block; */
  margin: 10px;
  text-align: center;
  /* border:1px solid silver; */
  padding: 15px;
  padding-top: 0px;
  border-radius: 15px;
  background-color: white;
  box-shadow: 0 2px 3px #ccc;
  border:1px solid $primary-color;
}

.WatcherSummaryCard label {
  font-weight: bold;
  text-transform: capitalize;
  margin-bottom: 5px;
  display: block;
}

.WatcherSummary-Score {
  margin-top: 5px;
  display: flex;
  label {
    font-weight: normal;
  }
}

.WatcherSummary-Score-Title {
  margin-right: 5px;
  width: 100%;
  text-align: left;
  text-transform: capitalize;
}

.WatcherSummary-Score-Value {
  padding: 5px;
  border-radius: 15px;
  width: 45px;
  margin: auto;
  text-align: center;
  /* border:1px solid black; */
}

.WatchersSummary-List-Container {
  overflow: scroll;
}
.WatchersSummary-List {
  /* overflow: scroll; */
  /* text-align: left; */
  /* width: 2500px; */
  display: flex;
}

.WatcherSummaryCard-HightScore {
  border: 1px solid #1890ff;
}

.WatcherFeedback {
  text-align: left;
  margin-top: 10px;
}

.WatcherFeedback-WatcherName {
  font-weight: bold;
  text-transform:capitalize;
}

.WatcherFeedback-Bad {
  color: orange;
  text-decoration: underline;
}

.WatcherFeedback-Good {
  color: green;
  text-decoration: underline;
}
