/* .NewTracker-StepsContent {
  margin-top: 16px;
  border: 1px dashed #e9e9e9;
  border-radius: 2px;
  background-color: #fafafa;
  min-height: 475px;
  text-align: center;
  padding-top: 20px;
} */

.NewTracker-StepsAction {
  margin-top: 24px;
}

.StepContent {
  margin: 25px;
}

.StepContent-Form {
  width: 50%;
  margin: auto;
}

.Steps-List {
  list-style: none;
}

.Steps-List button {
  width: 100%;
}

.Steps-List-Element {
  padding-bottom:4px;
  padding-left:11px;
  padding-right:11px;
  padding-top: 4px;
}

.Step-List-Item {
  width: 50%;
  text-align: center;
  margin: 10px auto;
  border-radius: 5px;
}

.Step-Content {
  margin: 10px;
  padding: 10px;
}

.Step-Content .ant-col.ant-form-item-control {
  text-align: left;
}

.Step-Buttons {
  display: block;
  width: 100%;
  clear: both;
  margin-top: 50px;
  padding-top: 50px;
  box-sizing: border-box;

}

.Steps-Preview {
  margin: 10px;
}

.Steps-FormActions {
  margin-top: 35px;
  margin-bottom: 35px;
}

.Steps-Table {
  /* width: 80%; */
}
.Steps-Table-Container {
  overflow: scroll;
}
