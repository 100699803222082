.Checklist {
  list-style: none;
  margin: 0px;
  padding: 0px;
  clear: both;
  /* height: 83px; */
  display: flex;
  overflow: scroll;

}

.Checklist li {
  /* float: left; */

}

.Checklist-Bullet {
  border-radius: 15px;
  border:1px solid black;
  width: 25px;
  height: 25px;
  margin: 4px;
  cursor: pointer;
}

.Checklist-Label {
  text-align: left;
  float: left;
  clear: both;
  width: 100%;
  font-weight: bold;
  margin-left: 10px;
  text-transform: capitalize;
}

.Checklist-Bullet-Active {
  background-color: green;
  color:white;
}

.Checklist-Bullet-Inactive {
  background-color: red;
  color:white;
}

.Checklist-Delete {
  display: inline-block;
  cursor: pointer;
  margin-right: 5px;
  /* text-align: left; */
  /* float: right; */
}
