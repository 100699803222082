.NewWatcher-Form {
  text-align: left;
}

.NewWatcher-Form ul {
  list-style: none;
  margin: 0px;
  padding: 0px;
}

.NewWatcher-Form ul li {
  padding: 5px;
}

.NewWatcher-Form-Footer {
  text-align: right;
}

.NewWatcher-Form-SwitchName {
  margin-left: 10px;
  display: inline-block;
  text-transform: capitalize;
}
