@import '../../styles/_colors.scss';

.TrackersEmpty {
  margin: 25px;
  padding: 25px;
  font-size: 22px;
  border:2px solid $primary-color;
  border-radius: 10px;
  font-weight: normal;
  color:$primary-color;
}
