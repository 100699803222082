.TrackCalendar {
  display: inline-block;
  margin: 5px;
}

.TrackCalendar-Calendar .react-calendar__tile--active{
  background-color: white;
  color: black;
}

.TrackCalendar-Day {
  background-color: red !important;
}

.TrackCalendar-Day-AWESOME {
  background-color: #C1E7E3;
}

.TrackCalendar-Day-GOOD {
  background-color: #FFF49C;
}

.TrackCalendar-Day-ALRIGHT {
  background-color: #FF756D;
}

.TrackCalendar-Day-MEH {
  background-color: #CEC8E4;
}
.TrackCalendar-Day-HORRIBLE {
  background-color: #82B3FF;
}
