@import '../../../../styles/_colors.scss';

.MoodSelector {
  border:1px solid black;
  padding: 10px;
  width: 40px;
  height: 40px;
  margin: 10px auto;
  cursor:pointer;
  display: inline-block;
  margin-left: 1px;
  color: #ccc;
  text-transform: uppercase;
  border-radius: 5px;
  box-shadow: 0 2px 3px #ccc;
}

.MoodSelector-Moods {
  list-style: none;
  margin: 0px;
  padding: 0px;
}

.MoodSelector-MoodButton {
  width: 100%;
}

.MoodSelector-MoodButton-Awesome {
  background-color: $level1;
  color: black;
}

.MoodSelector-MoodButton-Good {
  background-color: $level2;
  color: black;
}

.MoodSelector-MoodButton-Alright {
  background-color: $level3;
  color: black;
}

.MoodSelector-MoodButton-Meh {
  background-color: $level4;
  color: black;
}

.MoodSelector-MoodButton-Horrible {
  background-color: $level5;
  color: black;
}

.MoodSelector-Awesome {
  background-color: $level1;
  color: black;
}

.MoodSelector-Good {
  background-color: $level2;
  color: black;
}

.MoodSelector-Alright {
  background-color: $level3;
  color: black;
}

.MoodSelector-Meh {
  background-color: $level4;
  color: black;
}

.MoodSelector-Horrible {
  background-color: $level5;
  color: black;
}

.MoodSelector-Disabled {
  border-color: #ccc;
  cursor: not-allowed;
}
