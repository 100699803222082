.OptionsEntry-List {
  list-style: none;
  padding: 0px;
  margin: 0px;
}

.OptionsEntry-Button {
  width: 100%;
}

.OptionsEntry-Bullet {
  margin: 10px;
  padding: 10px;
  border-radius: 20px;
  border: 1px solid black;
  display: inline-block;
  cursor: pointer;
}
