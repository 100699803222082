.WatcherForm {
  text-align: left;
}

.WatcherForm ul {
  list-style: none;
  margin: 0px;
  padding: 0px;
}

.WatcherForm ul li {
  padding: 5px;
}

.WatcherForm-Footer {
  text-align: right;
  button {
    margin-left: 10px;
  }
}

.WatcherForm-SwitchName {
  margin-left: 10px;
  display: inline-block;
}
