@import '../../styles/_colors.scss';

.Auth {
  width: 100%;
  text-align: center;
  .hero_image {
    height: 69vh;
  }
  .join_text {
    font-size: 22px;
  }
  .tracker_text {
    font-size: 62px;
    margin-bottom: -3vw;
    margin-top: -2vw;
  }
  .buddy_text {
    font-size: 62px;
  }
  #join_tracker_buddy_text {
    top: 80px;
    left: 4%;
  }

  .Auth-Container {
    margin: auto;
    width: 780px;
    position: relative;
    border:1px solid $primary-color;
  }

  .track_your_behaviour {
    color: $primary-color;
  }

  .how_it_works {
    font-size: 32px;
    color: $primary-color;
  }
}

.Auth-Title {
  margin-top: 100px;
}

.Auth-SignIn {
  margin-top: 100px;
}

.Auth-Title  {
  padding: 10px;
}

.Auth-Title h1 {
  font-size: 56px;
  color: white;
  font-family: 'Xanh Mono', monospace;
}

.Auth-Title p {
  color: white;

}

p.Auth-SubTitle {
  /* color:blue; */
  font-weight: bold;
  font-size: 18px;
}

.Auth-Container h2 {
  color: white;
}

@media (max-width:990px) {
  .Auth-SignIn {
    margin-top: 0px;
  }
}
/* Design */


 @font-face {
  font-family: Raleway_bold;
  src: url(./fonts/Raleway-Bold.ttf);
}
@font-face {
  font-family: Raleway_light;
  src: url(./fonts/Raleway-Light.ttf);
}
@font-face {
  font-family: Raleway_medium;
  src: url(./fonts/Raleway-Medium.ttf);
}
@font-face {
  font-family: Raleway_regular;
  src: url(./fonts/Raleway-Regular.ttf);
}
@font-face {
  font-family: Raleway_thin;
  src: url(./fonts/Raleway-Thin.ttf);
}

.raleway_bold {
  font-family: Raleway_bold, sans-serif;
}

.raleway_light {
  font-family: Raleway_light, sans-serif;
}
.raleway_medium {
  font-family: Raleway_medium, sans-serif;
}
.raleway_regular {
  font-family: Raleway_regular, sans-serif;
}
.raleway_thin {
  font-family: Raleway_thin, sans-serif;
}
/* fonts end*/

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
a {
  text-decoration: none !important;
}
.hero_image {
  height: 110vh;
  width: 100%;
}
.join_text {
  text-align: center;
  font-size: 40px;
}
.tracker_text {
  text-align: center;
  font-size: 90px;
  margin-top: -34px;
  margin-bottom: -58px;
}
.buddy_text {
  font-size: 90px;
  text-align: center;
}
#join_tracker_buddy_text {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 86px;
  left: 6vw;
  color: white;
}
.top_horizontal_line {
  width: 180px;
  border-top: 2px solid white;
  margin-left: 50px;
  margin-top: -12px;
}
.left_middle_section_div {
  margin-top: -207px;
  max-width: 450px;
}
.middle_section_text {
  line-height: 1.6em;
  font-size: 20px;
}
#cellphone_image {
  height: 667px;
  width: 400px;
}
.cellphone {
  margin-top: -297px;
}
.icons {
  width: 87px;
}
.icons_text {
  text-align: center;
  color: #383396;
  margin-top: 10px;
  font-family: Raleway_medium, sans-serif;
}
#horizontal_line {
  text-align: center;
  margin-top: -58px;
}
.sign_in {
  text-align: center;
  z-index: 3;
  padding: 25px;
}
.btn {
  width: 17em;
  margin-top: 1em;
  margin-bottom: 1em;
}
.google_btn {
  background-color: white;
  border-color: #5a0a6e;
}
.email_btn {
  background-color: #5a0a6e;
  border-color: #5a0a6e;
  color: white;
}
.email_btn:hover {
  color: white;
}
.sign_in_icons {
  width: 21px;
  margin-right: 9px;
  margin-bottom: 2px;
}
.footer {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  // margin-top: 190px;
  // margin-bottom: -35vw;
  color: white;
}
footer a {
  color: white;
  z-index: 2;
}
footer a:hover {
  color: black;
}
.footer_image {
  max-height: 579px;
  width: 100%;
  position: relative;
  /* z-index: -10; */
}
.tracker_buddy {
  font-size: 2em;
  font-weight: 600;
  font-family: Raleway_bold, sans-serif;
}
.tracker {
  margin-top: 0px;
  margin-bottom: -14px;
}
.tracker_buddy_copyright {
  margin-left: -6em;
  position: absolute;
  bottom:0px;
}

@media (max-width: 767px) {
  .Auth {
    .sign_in {
      padding: 0px;
    }
    #cellphone_image {
      display: none;
    }
    .footer_image {
      height: 259px;
      -webkit-clip-path: inset(176px 0px 0px 0px);
      clip-path: inset(176px 0px 0px 0px);
    }
    .Auth-Container {
      width: 100%;
    }

    .hero_image {
      height: 25vh;
    }
  }
}

@media (max-width: 1000px) {
  .Auth {
    .hero_image {
      height: 50vh;
      width: 100%;
    }

    .join_text {
      font-size: 14px;
    }
    .tracker_text {
      font-size: 32px;
      margin-top: -10px;
      margin-bottom: -15px;
    }

    .buddy_text {
      font-size: 32px;
      margin-bottom: -1vw;
    }
    #join_tracker_buddy_text {
      top: 50px;
      left: 4%;
    }
    .top_horizontal_line {
      width: 50%;
      border-top: 2px solid white;
      margin-left: 33px;
      margin-top: -1px;
    }
    .left_middle_section_div {
      margin-top: 0px;
      margin-bottom: 70px;
      width: 80%;
    }
    .track_your_behaviour {
      line-height: 50px;
    }
    .terms_of_service {
      max-width: 300px !important;
    }
    .footer {
      margin-top: 44px;
      margin-bottom: -249px;
      font-size: 7px;
      /* font-size: 1.3vw; */
    }

    .tracker_buddy {
      font-size: 10px;
    }
    .tracker {
      margin-bottom: 0;
    }
    .tracker_buddy_copyright {
      margin-left: 0;
    }
  }
}

@media (min-width: 501px) and (max-width: 767px) {
 .Auth {
    .join_text {
      font-size: 2vw;
    }
    .tracker_text {
      font-size: 7vw;
      margin-bottom: -4vw;
    }
    .buddy_text {
      font-size: 7vw;
      margin-bottom: -1vw;
    }
    .top_horizontal_line {
      margin: auto;
    }
    .footer {
      font-size: 1.2vw;
    }
  }
}

@media (min-width: 623px) and (max-width: 767px) {
  .Auth {
    .hero_image {
      height: 60vh;
    }
  }
}

@media (min-width: 768px) and (max-width: 1000px) {
  .Auth {
    .hero_image {
      height: 90vh;
    }
    .join_text {
      font-size: 4vw;
    }
    .tracker_text {
      font-size: 6vw;
      margin-bottom: -3vw;
      margin-top: -2vw;
    }
    .buddy_text {
      font-size: 6vw;
    }
    #join_tracker_buddy_text {
      top: 80px;
      left: 6%;
    }
    .top_horizontal_line {
      margin: auto;
    }
    .left_middle_section_div {
      margin-top: -143px;
      margin-left: 30px;
    }

    .cellphone {
      margin-top: -214px;
    }
    .footer {
      margin-top: 14vw;
      margin-bottom: -34vw;
      font-size: 1.3vw;
    }
  }
}

.Auth-SubTitle {
  color: $primary-color;
  text-align: left;
  margin-top: 10px;
  font-size: 22px;
}

.Auth-ff {
  font-family: Raleway_light, sans-serif;
}

@media (min-width: 1001px) and (max-width: 1100px) {
  #join_tracker_buddy_text {
    left: 3vw;
  }
}

@media (min-width: 1001px) {
  .left_middle_section_div {
    margin-top: -193px;
  }
  .footer {
    font-size: 1.2vw;
  }
}
.alert-danger {
  padding: 10px;
  margin: 10px;
  font-weight: bold;
}
