.Tracker {
  margin: auto;
  /* width: 249px; */
  padding: 10px;
  border-radius: 5px;
}

.Tracker-Day {
  /* width: 249px; */
}

.Tracker-Day ul.Options li {
  margin: auto;
}

.Tracker-Day ul.Checklist li {
  margin: auto;
}

/* .Tracker:hover {
  background-color: #91d5ff;
} */

.Tracker-Day .Tracker-IsCurrentDate {
  background: none !important;
}

.Tracker-Label {
  font-weight: bold;
  font-size: 20px;
  text-align: left;
}

.Tracker-IsCurrentDate {
  /* background: rgba(144,238,144); */
  background: rgb(24, 144, 255, 0.5);
  border-radius: 15px;
  /* border:1px solid blue; */
}

.Tracker-IsCurrentDate .Bullet {
  border:2px solid black;
}

.Tracker-isFutureDate .Bullet {
  border-color: #ccc;
  color: #ccc;
  cursor: not-allowed;
}

.Tracker-isFutureDate {
  cursor: not-allowed;
}

.Tracker-TrackerNameIcon {
  margin-right: 10px;
}

.Tracker-Streak, .Tracker-Average {
  /* font-size: 12px; */
  /* margin-left: 5px; */
  /* color: silver; */
}

.Tracker-Label-Name {
  margin-right: 15px;
}

.Tracker-Demo {
  background-color: white !important;
}

.Tracker-Feedback {
  background-color: #fffbe6;
  border:1px solid #ffe58f;
  padding: 8px 15px;
  margin-top: 15px;
}

.Tracker-Feedback-TrackerName {
  font-weight: bold;
}

.Tracker-Content {
  margin-top: 10px;
}

.Tracker-Feedback-Red {
  background-color: #fff2f0;
  border:1px solid #ffccc7;
  padding: 8px 15px;
  margin-top: 25px;
}

.Tracker-Metrics {
  display: flex;
  overflow: scroll;
  list-style: none;
  margin:0px;
  padding:0px;
  clear: both;
}

.Tracker-Metrics li {
  float:left;
}
