.DaySummary {
  /* text-align: center; */
}

.DaySummary label  {
  margin-bottom: 10px;
  margin-top: 10px;
  display: block;

}
.DaySummary-Bullet {
    height: 50px;
    width: 50px;
    border-radius: 30px;
    border:1px solid black;
    text-align:center;
    padding: 13px;
}

.DaySummary-Bullet-Active {
  background-color: green;
}

.DaySummary-MoodTrackerPm, .DaySummary-MoodTrackerAm {
  width: 50px;
  height: 50px;
  border-radius: 5px;
  display: inline-block;
  border: 1px solid black
}

.DaySummary-Bullet-AmPm {
  width: 50px;
  height: 50px;
  border-radius: 5px;
  display: inline-block;
  border: 1px solid black;
}

.DaySummary-Bullet-Pm {
  margin-left: 10px;
}


.DaySummary-MoodTrackerAm {
  margin-left: 10px;
}

.DaySummary-Journal {
  height: 100px;
  overflow: scroll;
}

.DaySummary-JournalEmpty {
  font-style: italic;
}

.DaySummary-Title {
  margin-top: 25px;
}
