.Step2-Form {
  /* width: 500px; */
  width: 100%;
  margin: auto !important;
  padding: 25px;
}

.PredefinedTracker-Select {
  margin: 10px;
}
