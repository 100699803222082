@import '../../styles/_colors.scss';

.About {
  // padding-top: 100px;
  p {
    font-size: 22px;
  }
  text-align: left;
  h2 {
    color: $primary-color;
  }
}

.About-Title {
  color: $primary-color;
}

.About-align-left {
  text-align: left;
}

.About-align-right {
  text-align: right;
}

.About-Explain {
  margin-top: 50px;
  display: flex;
  align-items: center;

  p {
    font-size: 16px;
  }
}

.About-Text {
  width:80%;
}

.About-Icon {
  width: 30%;
  text-align: center;
  vertical-align: center;
}
