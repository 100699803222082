.Number-PmAm {
  /* height: 300px; */
}

.Number-Pm {
  height: 100px;
}

.Number-Am {
  height: 100px;
}
