.CookiePolicy {
  background-color: white;
  position: fixed;
  z-index: 10000;
  bottom: 0px;
  padding: 10px;
  border: 1px solid black;
  text-align: center;
  h1 {
    text-align: left;
    margin: 0px;
  }
  p {
    margin: auto;
    text-align: left;
    // max-width: 50%;
    padding: 10px;
  }

  a {
    text-align: left;
    display: block;
    padding: 10px;
  }
}

.CookiePolicy-Button {
  margin-top: 10px;
  button {
    margin-left: 10px;
  }
}
