.Footer {
  border-top: 1px solid silver;
  padding: 10px;
  margin-top: 25px;
  display: flex;
  justify-content: space-around;
  ul {
    list-style: none;
    text-align: left;
  }

  a {
    color: black;

    &:hover {
      color: #1890ff
    }

    .anticon {
      margin-right: 5px;
    }
  }

}
