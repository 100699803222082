.SelectSample .ant-select{
  width: 206px;
}

.NumberOptions-FormActions {
  margin-top: 35px;
}

.NumberOptions-Form {
  width: 300px;
  margin: auto !important;
}

.NumberOptions-Sample {
  background-color: #ccc;
  padding: 15px;
  border-radius: 15px;
}
