@import '../../styles/_colors.scss';

.Week-Container {
  margin: 20px;
  margin-top: 0px;
}
.Week-Button-Toolbar {
  margin-top: 25px;
  margin-bottom: 25px;
}
.Week-Button-Toolbar button {
  margin-left: 10px;
  margin-bottom: 10px;
}

.Week-Title {
  border-bottom: 2px solid $primary-color;
  text-align: left;
  margin-top: 25px;
}

.Week-Title label {
  font-size: 14px;
  font-weight: normal;
  /* background-color: silver; */
  color: $primary-color;
  // background-color: white;
  border-left:1px solid $primary-color;
  border-right: 1px solid $primary-color;
  border-top: 1px solid $primary-color;
  padding-top: 5px;
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 3px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  /* border-radius: 10px; */
}

@media (max-width:500px) {
  .Week-Container {
    margin: 5px;
    margin-top: 0px;
  }

  .Week-Toolbar .ant-col{
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .Week-Toolbar button {
    width: 100%;
  }
}
