.NumberEntry-List {
  list-style: none;
  padding: 0px;
  margin: 0px;
}

.NumberEntry-Button {
  width: 100%;
}

.NumberEntry-Bullet {
  /* margin: 10px;
  padding: 10px;
  border-radius: 20px;
  border: 1px solid black;
  display: inline-block;
  cursor: pointer; */
  font-size: 12px;
}

.NumberEntry-Value {
  text-align: center;
  /* text-decoration: underline dotted; */
}
