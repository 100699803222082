.TrackerFastEntry {
  display: inline-block;
  margin: 15px;
  // padding:10px;
  text-align: center;
  label {
    text-align: center;
    font-weight: bold;
    text-transform: capitalize;
  }
}

.TrackerFastEntry-Entry {
  display: flex;
  ul {
    margin: auto;
  }
  .Tracker-IsCurrentDate {
    background: white;
  }
}
