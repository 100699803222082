@import '../../../styles/_colors.scss';

.Box {
  box-shadow: 0 2px 3px #ccc;
  border-radius: 15px;
  margin: 20px;
  padding: 10px;
  background-color: white;
  transition-timing-function: ease-out;
  border:1px solid $primary-color;
}

.Box-Body {
  box-shadow: 0 2px 3px #ccc;
  border-radius: 15px;
  margin: 20px;
  margin-top: 0px;
  padding: 10px;
  background-color: white;
  transition-timing-function: ease-out;
  // border:1px solid $primary-color;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
  border-left: 1px solid $primary-color;
  border-right: 1px solid $primary-color;
  border-bottom: 1px solid $primary-color;
}

.Box-Top {
  background-color: white;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
  margin-bottom: 0px;
  padding: 10px;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 20px;
}

.Box-Title {
  margin-top: 20px;
  text-transform: capitalize;
  // background-color: white;
  color:black;
  margin-bottom: 0px;
  padding: 10px;
  margin-left: 20px;
  margin-right: 20px;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
  font-size: 22px;
  background:linear-gradient(to right, white, $primary-color); ;
  border-left: 1px solid $primary-color;
  border-right: 1px solid $primary-color;
  border-top: 1px solid $primary-color;
}

@media (max-width:500px) {
  .Box {
    margin: 5px;
  }
}
