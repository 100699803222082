.Options {
  list-style: none;
  margin: 0px;
  padding: 0px;
  clear: both;
  /* height: 80px; */
  display: flex;
  overflow: scroll;
}

.Options li {
  float: left;
}

.Options-Bullet {
  border-radius: 15px;
  border:1px solid black;
  width: 25px;
  height: 25px;
  margin: 4px;
  cursor: pointer;
}

.Options-Label {
  text-align: left;
  float: left;
  clear: both;
  width: 100%;
  font-weight: bold;
  margin-left: 10px;
}

.Options-Bullet.Options-Bullet-Active {
  background-color: green;
  color:white;
}

.Options-Delete {
  display: inline-block;
  cursor: pointer;
  margin-right: 5px;
  /* text-align: left; */
  /* float: right; */
}
