.Trackers-Tracker{
  clear: both;
  /* margin: 10px; */
  /* padding: 10px; */
  border-bottom: 1px solid silver;
}

.Trackers-List .Trackers-Tracker:last-child {
  border-bottom: 0px;
}
