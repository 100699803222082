.Account {

}

.Account-Value {
  text-align: left;
  padding: 5px;
  label {
    font-weight: bold;
    margin-right: 10px;
  }
}
