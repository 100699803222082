.ScrollableToolbar {
  /* margin-top: 20px; */
  /* border-bottom: 2px solid silver; */
  padding-bottom: 5px;
  /* box-shadow: 0px 10px 17px 0px rgba(0, 0, 0, 0.95); */
  /* margin-bottom: 25px; */
}
.ScrollableToolbar div {
  display: inline-block;
}

.ScrollableToolbar-Bakckward {
  display: inline-block;
  margin-right: 25px;
}

.ScrollableToolbar-Forward {
  margin-left: 25px;
}

.ScrollableDate-BackButton {
  display: block;
}
