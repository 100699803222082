.TrackersFastEntry-Category {
  margin-top: 25px;
  h2 {
    text-transform: capitalize;
    font-weight: normal;
  }
}

.TrackersFastEntry-List .TrackersFastEntry-Category:first-child {
  margin-top: 0px;
}
